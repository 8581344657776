import { Button, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import React from "react";
import Welcome from "./Welcome";

const EmployeeHome = () => {
  return (
    <React.Fragment>
      <Helmet title="Home" />
      <Row>
        <Welcome />
      </Row>
    </React.Fragment>
  );
};
export default EmployeeHome;
