import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/sidebar/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/navbar/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import AdminGuard from "../components/guards/AdminGuard";

// import dashboardItems from "../components/sidebar/dashboardItems";
import adminItems from "../components/sidebar/adminItems";
const Dashboard = ({ children }) => (
  <React.Fragment>
    <AdminGuard>
      <Wrapper>
        <Main>
          <div className="fixed-top">
            <Navbar />
            <Sidebar items={adminItems} />
          </div>
          <Content>
            <Container fluid="md" className="p-0">
              {children}
            </Container>
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </Wrapper>
    </AdminGuard>
  </React.Fragment>
);

export default Dashboard;
