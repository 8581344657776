import { Button } from "react-bootstrap";
import React from "react";

const AdminHome = () => {
  return (
    <React.Fragment>
      <h1>Admin Home</h1>
      <div className="d-flex flex-row flex-wrap w-50 justify-content-around">
        <Button variant="outline-primary">Work</Button>
        <Button variant="outline-primary">Summary</Button>
      </div>
    </React.Fragment>
  );
};
export default AdminHome;
