import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import SignIn from "../../components/auth/SignIn";

import { Link } from "react-router-dom";

const SignInPage = () => {
  console.log("Signin page displaying");
  return (
    <React.Fragment>
      <Helmet title="Sign In" />
      <div className="text-center mt-4">
        <img src={logo} alt="Logo" />
        <h2 className="kilimo-title-1">SMS Survey</h2>
        <p className="lead">Sign in to your account to continue</p>
      </div>

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center"></div>
            <SignIn />
          </div>
          {/* <Link to="/auth/sign-up">Create a free account</Link> */}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default SignInPage;
