import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      "Welcome back": "Welcome back",
    },
  },
  ef: {
    translation: {
      phone_number: "Namba ya Simu",
      isTarget: "Je, Ni mlengwa",
      isCommunicating: "Umekubali kuzungumza na kurekodiwa",
      reasonNotCommunicating: "Sababu ya Kukataa",
      otherReasonNotCommunicating: "Sababu nyingine ya kukataa",
      remembersReciveingSMS: "Unakumbuka kupokea SMS",
      messageContentRecieved: "Content ya SMS alizopokea",
      lastSMSRecieved: "Mara ya mwisho kupokea SMS",
      remembersContentOfLastSMS: "Unakumbuka maudhui ya SMS uliyopokea",
      contentOfLastSMSRecieved: "Aina ya maudhui ya SMS uliyopokea",
      actionAfterSMS: "Hatua uliyofanya baada ya kupokea SMS",
      otherActionAfterSMS: "Elezea hatua",
      continueRecievingSMS: "Unataka kuendelea kupokea SMS",
      reasonToNotRecieveSMS: "Sababu ya kutotaka kupokea SMS",
      whyNotRecieveSMSTextArea:
        "Elezea sababu ya kutotaka kuendelea kupokea SMS",
      messageContent: "Ujumbe unaokuja kwenye SMS",
      suggestions: "Maoni",
    },
  },
  fr: {
    translation: {
      "Welcome back": "Bon retour",
    },
  },
  de: {
    translation: {
      "Welcome back": "Willkommen zurück",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ef",
  fallbackLng: "ef",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
