import React from "react";
import useAuth from "../../../hooks/useAuth";
const Welcome = () => {
  const { user } = useAuth();
  return (
    <div className="welcome">
      <h1>Welcome, {user.email}</h1>
      <h5>Good to see you!</h5>
    </div>
  );
};
export default Welcome;
