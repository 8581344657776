import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { AuthProvider } from "./contexts/JWTContext";
import routes from "./routes";
const App = () => {
  const content = useRoutes(routes);
  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | SMS Survey" defaultTitle="SMS Survery" />
      <AuthProvider>{content}</AuthProvider>
    </HelmetProvider>
  );
};
export default App;
