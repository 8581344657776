import React from "react";

// import useSidebar from "../../hooks/useSidebar";
import SidebarFooter from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
// import { ReactComponent as Logo } from "../../assets/img/logo.svg";
// import logo from "../../../public/sema-icon.png";

const Sidebar = ({ items, showFooter = true }) => {
  // const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar`}>
      <div className="sidebar-content">
        <SidebarNav items={items} />
      </div>
    </nav>
  );
};

export default Sidebar;
