import { Info, Users } from "react-feather";

const adminSection = [
  {
    href: "/emp",
    icon: Info,
    title: "Home",
  },
];

const manageSection = [
  {
    href: "/emp/form",
    icon: Users,
    title: "Survey Form",
  },
  {
    href: "/emp/records",
    icon: Users,
    title: "Records",
  },
];

const navItems = [
  {
    title: "Administration",
    pages: adminSection,
  },
  {
    title: "Manage",
    pages: manageSection,
  },
];

export default navItems;
