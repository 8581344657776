import { Formik } from "formik";
import { Button, Form, Alert, Card, Container } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import NotyfContext from "../../contexts/NotyfContext";
import React, { useContext } from "react";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import useAuth from "../../hooks/useAuth";
const TheForm = () => {
  const notyf = useContext(NotyfContext);
  const { recordSurvey, user } = useAuth();

  const actions = [
    "Lifestyle change",
    "Screening",
    "Clinic Visit",
    "Taking medication",
    "Discuss with family",
    "Other",
  ];

  const initialValues = {
    phone_number: "",
    isTarget: "",
    isCommunicating: "",
    reasonNotCommunicating: "",
    otherReasonNotCommunicating: "",
    remembersReciveingSMS: "",
    messageContentRecieved: "",
    lastSMSRecieved: "",
    remembersContentOfLastSMS: "",
    contentOfLastSMSRecieved: "",
    actionAfterSMS: [],
    otherActionAfterSMS: "",
    continueRecievingSMS: "",
    reasonToNotRecieveSMS: "",
    whyNotRecieveSMSTextArea: "",
    messageContent: "",
    suggestions: "",
    user: user.email,
  };
  return (
    <React.Fragment>
      <Helmet title="Survey Form" />

      <Card>
        <Card.Header>
          <Card.Title tag="h4">Survey form title</Card.Title>
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              phone_number: Yup.string("Jaza nambari tu")
                .max(10, "Namba ya simu imezidi")
                .min(10, "Namba ya simu haijatimia")
                .required("Namba ya simu inahitajika!"),
              isTarget: Yup.string().required("Inahitajika!"),
              isCommunicating: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () => Yup.string().required("Inahitajika!"),
                otherwise: () => Yup.string().notRequired(),
              }),
              reasonNotCommunicating: Yup.mixed().when("isCommunicating", {
                is: "Hapana",
                then: () => Yup.string().required("Inahitajika"),
                otherwise: () => Yup.string().notRequired(),
              }),
              otherReasonNotCommunicating: Yup.mixed().when("isTarget", {
                is: "Hapana",
                then: () => Yup.string(),
                otherwise: () =>
                  Yup.mixed().when("reasonNotCommunicating", {
                    is: "Nyingine",
                    then: () => Yup.string().required("Inahitajika!"),
                    otherwise: () => Yup.string().default(""),
                  }),
              }),
              remembersReciveingSMS: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () =>
                  Yup.mixed().when("isCommunicating", {
                    is: "Ndio",
                    then: () => Yup.string().required("Inahitajika!"),
                  }),
                otherwise: () => Yup.string().notRequired(),
              }),
              messageContentRecieved: Yup.mixed().when(
                "remembersReciveingSMS",
                {
                  is: "Ndio",
                  then: () => Yup.string().required("Inahitajika!"),
                },
              ),
              lastSMSRecieved: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () =>
                  Yup.mixed().when("isCommunicating", {
                    is: "Ndio",
                    then: () =>
                      Yup.string().when("remembersReciveingSMS", {
                        is: "Ndio",
                        then: () => Yup.string().required("Inahitajika!"),
                      }),
                  }),
                otherwise: () => Yup.string().notRequired(),
              }),
              remembersContentOfLastSMS: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () =>
                  Yup.mixed().when("isCommunicating", {
                    is: "Ndio",
                    then: () =>
                      Yup.mixed().when("remembersReciveingSMS", {
                        is: "Ndio",
                        then: () => Yup.string().required("Inahitajika!"),
                      }),
                    otherwise: () => Yup.string().notRequired(),
                  }),
              }),
              contentOfLastSMSRecieved: Yup.mixed().when(
                "remembersContentOfLastSMS",
                {
                  is: "Ndio",
                  then: () => Yup.string().required("Inahitajika"),
                  otherwise: () => Yup.string().notRequired(),
                },
              ),
              actionAfterSMS: Yup.mixed().when("remembersContentOfLastSMS", {
                is: "Ndio",
                then: () => Yup.array().required("Inahitajika"),
              }),
              otherActionAfterSMS: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () =>
                  Yup.mixed().when("remembersReciveingSMS", {
                    is: "Ndio",
                    then: () => Yup.string(),
                  }),
              }),
              continueRecievingSMS: Yup.mixed().when("isTarget", {
                is: "Ndio",
                then: () =>
                  Yup.mixed().when("isCommunicating", {
                    is: "Ndio",
                    then: () => Yup.string().required("Inahitajika"),
                  }),
                otherwise: () => Yup.string().notRequired(),
              }),
              reasonToNotRecieveSMS: Yup.mixed().when("continueRecievingSMS", {
                is: "Hapana",
                then: () => Yup.string().required("Inahitajika"),
              }),
              whyNotRecieveSMSTextArea: Yup.mixed().when(
                "reasonToNotRecieveSMS",
                {
                  is: "Nyingine",
                  then: () => Yup.string().required("Inahitajika!"),
                },
              ),
              messageContent: Yup.string(),
              suggestions: Yup.string(),
            })}
            onSubmit={async (
              values,
              { setErrors, setSubmitting, setStatus, resetForm },
            ) => {
              try {
                //submit to the server
                setSubmitting(true);
                const actionsAfterSMSString = values.actionAfterSMS.toString();
                values.actionAfterSMS = actionsAfterSMSString;
                console.log("Values: ", values);
                const res = await recordSurvey(values);
                resetForm({ values: initialValues });
                notyf.success("Imewasilishwa");
                setSubmitting(false);
              } catch (err) {
                notyf.error("Haija wasilishwa. Kuna tatizo limetokea!");
                setStatus("Something went wrong");
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              setFieldValue,
              resetForm,
              handleChange,
              handleSubmit,
              isSubmitting,
              status,
              touched,
              values,
            }) => (
              <div className="survey-form-container">
                <Form onSubmit={handleSubmit}>
                  {status && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{status}</div>
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Namba ya simu</Form.Label>
                    <Form.Control
                      size="lg"
                      inputMode="numeric"
                      name="phone_number"
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="0624..."
                      isInvalid={Boolean(
                        touched.phone_number && errors.phone_number,
                      )}
                    />
                    {!!touched.phone_number && (
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Je, Ni mlengwa?</Form.Label>
                    <Form.Check
                      type="radio"
                      name="isTarget"
                      label="Ndio"
                      checked={values.isTarget === "Ndio" ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Ndio"
                      isInvalid={Boolean(touched.isTarget && errors.isTarget)}
                    />
                    <Form.Check
                      type="radio"
                      name="isTarget"
                      value="Hapana"
                      checked={values.isTarget === "Hapana" ? true : false}
                      label="Hapana"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(touched.isTarget && errors.isTarget)}
                    />
                    {!!touched.isTarget && (
                      <Form.Control.Feedback type="invalid">
                        {errors.isTarget}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Je, Umekubali kuzungumza na kurekodiwa?
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      label="Ndio"
                      name="isCommunicating"
                      checked={values.isCommunicating === "Ndio" ? true : false}
                      disabled={values.isTarget !== "Ndio" ? true : false}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Ndio"
                      isInvalid={Boolean(
                        touched.isCommunicating && errors.isCommunicating,
                      )}
                    />
                    <Form.Check
                      type="radio"
                      name="isCommunicating"
                      disabled={values.isTarget !== "Ndio" ? true : false}
                      label="Hapana"
                      value="Hapana"
                      checked={
                        values.isCommunicating === "Hapana" ? true : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.isCommunicating && errors.isCommunicating,
                      )}
                    />
                    {!!touched.isCommunicating && (
                      <Form.Control.Feedback type="invalid">
                        {errors.isCommunicating}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Sababu ya Kukataa</Form.Label>
                    <Form.Select
                      disabled={
                        values.isCommunicating === "Hapana" ? false : true
                      }
                      value={values.reasonNotCommunicating}
                      name="reasonNotCommunicating"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option></option>
                      <option>Sina muda</option>
                      <option>Sitaki</option>
                      <option>Simu haina chaji</option>
                      <option>Nyingine</option>
                    </Form.Select>
                    {!!touched.reasonNotCommunicating && (
                      <Form.Control.Feedback type="invalid">
                        {errors.reasonNotCommunicating}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Sababu nyingine ya kukataa</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={values.otherReasonNotCommunicating}
                      size="lg"
                      disabled={
                        values.reasonNotCommunicating === "Nyingine"
                          ? false
                          : true
                      }
                      name="otherReasonNotCommunicating"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.otherReasonNotCommunicating &&
                          errors.otherReasonNotCommunicating,
                      )}
                    />
                    {!!touched.otherReasonNotCommunicating && (
                      <Form.Control.Feedback type="invalid">
                        {errors.otherReasonNotCommunicating}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Unakumbuka kupokea SMS?</Form.Label>
                    <Form.Check
                      type="radio"
                      label="Ndio"
                      checked={
                        values.remembersReciveingSMS === "Ndio" ? true : false
                      }
                      disabled={values.isTarget === "Ndio" ? false : true}
                      name="remembersReciveingSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Ndio"
                      isInvalid={Boolean(
                        touched.remembersReciveingSMS &&
                          errors.remembersReciveingSMS,
                      )}
                    />
                    <Form.Check
                      type="radio"
                      label="Hapana"
                      disabled={values.isTarget === "Ndio" ? false : true}
                      checked={
                        values.remembersReciveingSMS === "Hapana" ? true : false
                      }
                      name="remembersReciveingSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Hapana"
                      isInvalid={Boolean(
                        touched.remembersReciveingSMS &&
                          errors.remembersReciveingSMS,
                      )}
                    />
                    <Form.Check
                      type="radio"
                      label="Sijawai kupokea"
                      disabled={values.isTarget === "Ndio" ? false : true}
                      checked={
                        values.remembersReciveingSMS === "Sijawai kupokea"
                          ? true
                          : false
                      }
                      name="remembersReciveingSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Sijawai kupokea"
                      isInvalid={Boolean(
                        touched.remembersReciveingSMS &&
                          errors.remembersReciveingSMS,
                      )}
                    />
                    {!!touched.remembersReciveingSMS && (
                      <Form.Control.Feedback type="invalid">
                        {errors.remembersReciveingSMS}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Content ya SMS alizopokea</Form.Label>
                    <Form.Select
                      disabled={
                        values.remembersReciveingSMS === "Ndio" ? false : true
                      }
                      value={values.messageContentRecieved}
                      name="messageContentRecieved"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option></option>
                      <option>NCD</option>
                      <option>Non-NCD</option>
                    </Form.Select>
                    {!!touched.messageContentRecieved && (
                      <Form.Control.Feedback type="invalid">
                        {errors.messageContentRecieved}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Mara ya mwisho kupokea SMS</Form.Label>
                    <Form.Select
                      name="lastSMSRecieved"
                      onChange={handleChange}
                      disabled={
                        values.remembersReciveingSMS === "Ndio" ? false : true
                      }
                      onBlur={handleBlur}
                      value={values.lastSMSRecieved}
                    >
                      <option></option>
                      <option>Wiki moja</option>
                      <option>Wiki mbili</option>
                      <option>Mwezi mmoja</option>
                      <option>Miezi mitatu</option>
                      <option>Sikumbuki</option>
                    </Form.Select>
                    {!!touched.lastSMSRecieved && (
                      <Form.Control.Feedback type="invalid">
                        {errors.lastSMSRecieved}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Unakumbuka maudhui ya SMS uliyopokea?
                    </Form.Label>
                    <Form.Check
                      type="radio"
                      label="Ndio"
                      disabled={
                        values.isTarget === "Ndio" &&
                        values.remembersReciveingSMS === "Ndio"
                          ? false
                          : true
                      }
                      name="remembersContentOfLastSMS"
                      checked={
                        values.remembersContentOfLastSMS === "Ndio"
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Ndio"
                      isInvalid={Boolean(
                        touched.remembersContentOfLastSMS &&
                          errors.remembersContentOfLastSMS,
                      )}
                    />
                    <Form.Check
                      type="radio"
                      disabled={
                        values.isTarget === "Ndio" &&
                        values.remembersReciveingSMS === "Ndio"
                          ? false
                          : true
                      }
                      label="Hapana"
                      name="remembersContentOfLastSMS"
                      checked={
                        values.remembersContentOfLastSMS === "Hapana"
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Hapana"
                      isInvalid={Boolean(
                        touched.remembersContentOfLastSMS &&
                          errors.remembersContentOfLastSMS,
                      )}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Aina ya maudhui ya SMS uliyopokea</Form.Label>
                    <Form.Select
                      disabled={
                        values.remembersContentOfLastSMS === "Ndio"
                          ? false
                          : true
                      }
                      value={values.contentOfLastSMSRecieved}
                      name="contentOfLastSMSRecieved"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option></option>
                      <option>Lifestyle</option>
                      <option>Education</option>
                      <option>Prevention</option>
                      <option>Screening</option>
                      <option>Adherence to care</option>
                    </Form.Select>
                    {!!touched.contentOfLastSMSRecieved && (
                      <Form.Control.Feedback type="invalid">
                        {errors.contentOfLastSMSRecieved}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Hatua uliyofanya baada ya kupokea SMS
                    </Form.Label>
                    <div className="d-flex flex-row flex-wrap px-3">
                      {actions.map((act) => (
                        <Form.Check
                          className="p-3"
                          type="checkbox"
                          disabled={
                            values.remembersContentOfLastSMS === "Ndio"
                              ? false
                              : true
                          }
                          label={act}
                          name="actionAfterSMS"
                          checked={values.actionAfterSMS.includes(act)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={act}
                          isInvalid={Boolean(
                            touched.actionAfterSMS && errors.actionAfterSMS,
                          )}
                        />
                      ))}
                    </div>
                    {!!touched.actionAfterSMS && (
                      <Form.Control.Feedback type="invalid">
                        {errors.actionAfterSMS}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Elezea hatua</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={values.otherActionAfterSMS}
                      size="lg"
                      disabled={
                        values.remembersContentOfLastSMS === "Ndio"
                          ? false
                          : true
                      }
                      name="otherActionAfterSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.otherActionAfterSMS &&
                          errors.otherActionAfterSMS,
                      )}
                    />
                    {!!touched.otherActionAfterSMS && (
                      <Form.Control.Feedback type="invalid">
                        {errors.otherActionAfterSMS}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Unataka kuendelea kupokea SMS?</Form.Label>
                    <Form.Check
                      type="radio"
                      disabled={values.isTarget === "Ndio" ? false : true}
                      label="Ndio"
                      checked={
                        values.continueRecievingSMS === "Ndio" ? true : false
                      }
                      name="continueRecievingSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Ndio"
                      isInvalid={Boolean(
                        touched.continueRecievingSMS &&
                          errors.continueRecievingSMS,
                      )}
                    />
                    <Form.Check
                      type="radio"
                      label="Hapana"
                      disabled={values.isTarget === "Ndio" ? false : true}
                      checked={
                        values.continueRecievingSMS === "Hapana" ? true : false
                      }
                      name="continueRecievingSMS"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="Hapana"
                      isInvalid={Boolean(
                        touched.continueRecievingSMS &&
                          errors.continueRecievingSMS,
                      )}
                    />
                    {!!touched.continueRecievingSMS && (
                      <Form.Control.Feedback type="invalid">
                        {errors.continueRecievingSMS}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Sababu ya kutotaka kupokea SMS</Form.Label>
                    <Form.Select
                      disabled={
                        values.continueRecievingSMS === "Hapana" ? false : true
                      }
                      value={values.reasonToNotRecieveSMS}
                      name="reasonToNotRecieveSMS"
                      onChange={handleChange}
                      // defaultValue={values.reasonToNotRecieveSMS}
                      onBlur={handleBlur}
                    >
                      <option></option>
                      <option>Sitaki tu</option>
                      <option>Zinasumbua</option>
                      <option>SMS nyingi sana</option>
                      <option>Sina muda wa kusoma</option>
                      <option>Nyingine</option>
                    </Form.Select>
                    {!!touched.reasonToNotRecieveSMS && (
                      <Form.Control.Feedback type="invalid">
                        {errors.reasonToNotRecieveSMS}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>
                      Elezea sababu ya kutotaka kuendelea kupokea SMS
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      size="lg"
                      value={values.whyNotRecieveSMSTextArea}
                      disabled={
                        values.reasonToNotRecieveSMS === "Nyingine"
                          ? false
                          : true
                      }
                      name="whyNotRecieveSMSTextArea"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.whyNotRecieveSMSTextArea &&
                          errors.whyNotRecieveSMSTextArea,
                      )}
                    />
                    {!!touched.whyNotRecieveSMSTextArea && (
                      <Form.Control.Feedback type="invalid">
                        {errors.whyNotRecieveSMSTextArea}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Ujumbe unaokuja kwenye SMS</Form.Label>
                    <Form.Select
                      name="messageContent"
                      onChange={handleChange}
                      disabled={values.isTarget === "Ndio" ? false : true}
                      onBlur={handleBlur}
                      value={values.messageContent}
                    >
                      <option></option>
                      <option>Lifestyle</option>
                      <option>Education</option>
                      <option>Prevention</option>
                      <option>Screening</option>
                      <option>Adherence to care</option>
                    </Form.Select>
                    {!!touched.messageContent && (
                      <Form.Control.Feedback type="invalid">
                        {errors.messageContent}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Maoni</Form.Label>
                    <Form.Control
                      as="textarea"
                      size="lg"
                      disabled={values.isTarget === "Ndio" ? false : true}
                      name="suggestions"
                      onChange={handleChange}
                      value={values.suggestions}
                      onBlur={handleBlur}
                      isInvalid={Boolean(
                        touched.suggestions && errors.suggestions,
                      )}
                    />
                    {!!touched.suggestions && (
                      <Form.Control.Feedback type="invalid">
                        {errors.suggestions}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <div className="d-flex flex-row justify-content-between">
                    <Button
                      variant="secondary"
                      onClick={() => resetForm({ values: initialValues })}
                    >
                      Clear
                    </Button>
                    <Button
                      type="submit"
                      variant="success"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      Wasilisha
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default TheForm;
