import { Card, ListGroup } from "react-bootstrap";
import React from "react";
import { Helmet } from "react-helmet-async";
import { getRecord } from "../../../redux/slices/recordSlice";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { useParams } from "react-router-dom";
import useAppSelector from "../../../hooks/useAppSelector";

const Record = () => {
  const { id } = useParams();
  console.log("id: ", id);
  const { t, i18n } = useTranslation();
  const data = useAppSelector((state) => getRecord(state, id));
  console.log("datas", data);
  // const data = {
  //   phone_number: "255624369874",
  //   isTarget: true,
  //   isCommunicating: false,
  //   reasonNotCommunicating: "Nyingine",
  //   otherReasonNotCommunicating:
  //     "Majukumu ni mengi, hakuna muda wa kushika simu",
  //   remembersReciveingSMS: true,
  //   messageContentRecieved: "NCD",
  //   lastSMSRecieved: "Wiki Moja",
  //   remembersContentOfLastSMS: true,
  //   contentOfLastSMSRecieved: "Lifestyle",
  //   actionAfterSMS: "Nyingine",
  //   otherActionAfterSMS: "Nilitulia tu, maisha yalisonga",
  //   continueRecievingSMS: false,
  //   reasonToNotRecieveSMS: "Nyingine",
  //   whyNotRecieveSMSTextArea: "Sipendi SMS",
  //   messageContent: "Adherence to care",
  //   suggestions:
  //     "If you have checked all the above points and are still experiencing issues, it would be helpful to see more of your Sass code to identify any potential problems.",
  // };
  return (
    <React.Fragment>
      <Helmet title="Record" />
      <Card>
        <Card.Header>
          <Card.Title tag="h5">Record title</Card.Title>
        </Card.Header>
        <Card.Body>
          <ListGroup flush>
            {Object.keys(data).map((key) => (
              <ListGroup.Item key={key}>
                <strong>{i18n.t(key)}</strong> : {data[key]}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
export default Record;
