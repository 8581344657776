import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import "./loading.css";

// For routes that can only be accessed by admin users
function AdminGuard({ children }) {
  const [isActive, setisActive] = useState(false);
  const { onAuthStateChanged, getAuth } = useAuth();
  const navigate = useNavigate();

  const auth = getAuth();

  useEffect(() => {
    const checkAuth = async () => {
      await onAuthStateChanged(auth, (user) => {
        if (user) {
          setisActive(true);
          return <React.Fragment>{children}</React.Fragment>;
        } else {
          // User is signed out
          // ...
          setisActive(false);
          navigate("/auth/sign-in");
        }
      });
    };
    checkAuth();
  }, []);

  if (isActive) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  return (
    <div className="cont">
      <div className="waviy">
        <span style={{ "--i": "1" }}>L</span>
        <span style={{ "--i": "2" }}>o</span>
        <span style={{ "--i": "3" }}>a</span>
        <span style={{ "--i": "4" }}>d</span>
        <span style={{ "--i": "5" }}>i</span>
        <span style={{ "--i": "6" }}>n</span>
        <span style={{ "--i": "7" }}>g</span>
        <span style={{ "--i": "8" }}>.</span>
      </div>
    </div>
  );
}

export default AdminGuard;
