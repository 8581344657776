import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, Container } from "react-bootstrap";
import logo from "../../assets/images/logo.png";
import {
  AlertCircle,
  Bell,
  BellOff,
  Home,
  MessageCircle,
  UserPlus,
  Search,
} from "react-feather";

import NavbarUser from "./NavbarUser";

const NavbarComponent = () => {
  const { t } = useTranslation();

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <Container fluid="md">
        <img src={logo} alt="logo" />{" "}
      </Container>
      <Navbar.Collapse className="px-2">
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
