import { createSlice } from "@reduxjs/toolkit";

const recordSlice = createSlice({
  name: "record",
  initialState: {
    allRecords: [],
    currentRecord: {},
  },
  reducers: {
    setRecords: (state, records) => {
      console.log("Setting fetched records: ", records.payload);
      state.allRecords = records.payload;
    },
    getRecord: (state, action) => {
      console.log("Getting record with ID: ", action.payload);
      const x = state.allRecords.find((record) => record.id === action.payload);
      console.log("In slice x: ", x);
    },
    deleteRecord: (state, action) => {
      const recordIdToDelete = action.payload;
      const newData = state.allRecords.filter(
        (record) => record.id !== recordIdToDelete,
      );
      console.log("Data after delition: ", newData);
      state.allRecords = newData;
    },
  },
});

export function setRecords(values) {
  return (dispatch) => {
    dispatch(recordSlice.actions.setRecords(values));
  };
}
export function deleteRecord(id) {
  return (dispatch) => {
    dispatch(recordSlice.actions.deleteRecord(id));
  };
}
export const getRecord = (state, recordId) => {
  return state.recordSlice.allRecords.find((record) => record.id === recordId);
};

export default recordSlice.reducer;
