import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { setAccessToken } from "../redux/slices/authSlice";
import { setRecords, deleteRecord } from "../redux/slices/recordSlice";
import { useNavigate } from "react-router-dom";
import useAppDispatch from "./../hooks/useAppDispatch";
import NotyfContext from "./NotyfContext";
// import axios from "../utils/axios";
import axios from "axios";
import { isValidToken, setSession } from "../utils/jwt";

import { initializeApp } from "firebase/app";
import { firebaseConfig } from "../config";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  collection,
  addDoc,
  doc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const db = getFirestore(app);

  const appDispatch = useAppDispatch();
  const [isReload, setIsReload] = useState(true);
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  //this one
  useEffect(() => {
    const initialize = async () => {
      try {
        //Initialize firebase app
        await onAuthStateChanged(auth, (user) => {
          if (user) {
            // User is signed in, see docs for a list of available properties
            // https://firebase.google.com/docs/reference/js/auth.user
            const uid = user.uid;
            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
            // ...
          } else {
            // User is signed out
            // ...
          }
        });
      } catch (err) {}
    };

    initialize();
  }, []);

  const recordSurvey = async (values) => {
    try {
      const docRef = await addDoc(collection(db, "surveys"), values);
      return docRef;
    } catch (err) {
      notyf.error("Connection problem!");
      return err;
    }
  };

  const deleteSurvey = async (id) => {
    try {
      const res = await deleteDoc(doc(db, "surveys", id));
      // appDispatch(deleteRecord(id));
      notyf.success("Deleted!");
      return res;
    } catch (err) {
      notyf.error("Error deleting");
      return err;
    }
  };
  //this one
  const signIn = async (email, password) => {
    // axios.defaults.withCredentials = true;
    try {
      const response = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          setAccessToken(user.accessToken);
          // ...
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
          navigate("/emp");
          return user;
        })
        .catch((error) => {
          notyf.error("Invalid Credentials");
          return "Invalid Credentials!";
        });
    } catch (e) {
      return e.response;
    }
  };

  const fetchSurveys = async () => {
    try {
      const dataArray = [];
      const querySnapshot = await getDocs(collection(db, "surveys"));

      querySnapshot.forEach((doc) => {
        const timestamp = doc._document.createTime;
        const timestampInSeconds = timestamp.timestamp.seconds;
        const date = new Date(timestampInSeconds * 1000); // Convert seconds to milliseconds

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        dataArray.push({
          id: doc.id,
          createTime: formattedDate,
          ...doc.data(),
        });
      });
      appDispatch(setRecords(dataArray));
      return dataArray;
    } catch (err) {}
  };

  const logOut = async () => {
    setSession(null);
    await signOut(auth);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        getAuth,
        logOut,
        signUp,
        recordSurvey,
        fetchSurveys,
        deleteSurvey,
        onAuthStateChanged,
        // resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
