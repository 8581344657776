import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Card, Button } from "react-bootstrap";
import FullTable from "../../../ui/tables/FullTable";
import { useNavigate } from "react-router-dom";
import useAppSelector from "../../../hooks/useAppSelector";
import useAuth from "../../../hooks/useAuth";
import NotyfContext from "../../../contexts/NotyfContext";
import useAppDispatch from "../../../hooks/useAppDispatch";
import { deleteRecord } from "../../../redux/slices/recordSlice";
import * as xlsx from "xlsx";

const Records = () => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState([]);
  const notyf = useContext(NotyfContext);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchSurveys, deleteSurvey } = useAuth();

  const navigate = useNavigate();
  // fetchSurveys();
  const rows = useAppSelector((state) => state.recordSlice.allRecords);

  useEffect(() => {
    const fetchData = async () => {
      const row = await fetchSurveys();
      setData(row);
    };
    fetchData();
  }, []);
  const cols = [
    {
      Header: "Phone number",
      accessor: "phone_number",
    },
    {
      Header: "Ni Mlengwa?",
      accessor: "isTarget",
    },
    {
      Header: "Amekubali kuzungumza",
      accessor: "isCommunicating",
    },
    {
      Header: "Action",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-around">
          <Button size="sm" variant="success" onClick={() => navigate(value)}>
            View
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => handleDelete(value)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  // const handleExport = async () => {
  //   try {
  //     window.open(
  //       "https://sms_survey_worker.barakaurio0.workers.dev",
  //       "_blank",
  //     );
  //   } catch (err) {
  //     notyf.error("An error occured. Check Network");
  //   }
  // };
  const generateExcelFile = async () => {
    console.log("Rows:", rows);
    const dataList = [];
    rows.map((data, index) => {
      const vals = { ...data };
      const datas = {};
      datas["createTime"] = vals.createTime;
      datas["Namba ya Simu"] = vals.phone_number;
      datas["Ni mlengwa"] = vals.isTarget;
      datas["Amekubali kuzungumza na kurekodiwa"] = vals.isCommunicating;
      datas["Sababu ya kukataa"] = vals.reasonNotCommunicating;
      datas["Sababu nyingine ya kukataa"] = vals.otherReasonNotCommunicating;
      datas["Anakumbuka kupokea SMS"] = vals.remembersReciveingSMS;
      datas["Content za SMS alizopokea"] = vals.messageContentRecieved;
      datas["Mara ya mwisho kupokea SMS"] = vals.lastSMSRecieved;
      datas["Anakumbuka content ya SMS aliyopokea"] =
        vals.remembersContentOfLastSMS;
      datas["Content ya SMS anayoikumbuka"] = vals.contentOfLastSMSRecieved;
      datas["Action baada ya SMS"] = vals.actionAfterSMS;
      datas["Action nyingine baada ya SMS"] = vals.otherActionAfterSMS;
      datas["Aendelee kupokea SMS"] = vals.continueRecievingSMS;
      datas["Sababu ya kukataa kuendelea"] = vals.reasonToNotRecieveSMS;
      datas["Sababu nyingine ya kukataa kuendelea"] =
        vals.whyNotRecieveSMSTextArea;
      datas["Ujumbe kwenye SMS zinazokuja"] = vals.messageContent;
      datas["Maoni"] = vals.suggestions;
      datas["User"] = vals.user;
      dataList.push(datas);
    });
    const worksheet = xlsx.utils.json_to_sheet(dataList);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelFile = xlsx.write(workbook, {
      bookType: "xlsx",
      type: "buffer",
    });
    // return excelFile;
    const blobUrl = URL.createObjectURL(
      new Blob([excelFile], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }),
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = "my_generated_file.xlsx";

    document.body.appendChild(downloadLink);
    downloadLink.click();

    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(blobUrl);
  };
  const handleDelete = async (id) => {
    try {
      await deleteSurvey(id);
      dispatch(deleteRecord(id));
    } catch (err) {
      notyf.error("Error occurred while trying to delete");
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Records" />
      <Card>
        <Card.Header>
          <div className="d-flex flex-row justify-content-between">
            <Card.Title tag="h5">Records</Card.Title>
            <Button onClick={generateExcelFile}>Export</Button>
          </div>
        </Card.Header>
        <Card.Body>
          <FullTable values={rows} cols={cols} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};
export default Records;
