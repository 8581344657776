import React from "react";

//Layouts
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";
import AuthLayout from "./layouts/Auth";

//Auth Pages
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

//Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import GuestGuard from "./components/guards/GuestGuard";

//Pages
import AdminHome from "./pages/admins/AdminHome";
import EmployeeHome from "./pages/employees/home/EmployeeHome";
import TheForm from "./pages/employees/TheForm";
import Records from "./pages/employees/records/Records";
import Record from "./pages/employees/records/Record";

const routes = [
  {
    path: "/",
    element: (
      <DashboardLayout>
        <EmployeeHome />
      </DashboardLayout>
    ),
  },
  {
    path: "/auth/sign-in",
    element: (
      <AuthLayout>
        <SignIn />
      </AuthLayout>
    ),
  },
  {
    path: "/auth/sign-up",
    element: (
      <AuthLayout>
        <SignUp />
      </AuthLayout>
    ),
  },
  {
    path: "/emp",
    element: (
      <DashboardLayout>
        <EmployeeHome />
      </DashboardLayout>
    ),
  },
  {
    path: "/emp/form",
    element: (
      <DashboardLayout>
        <TheForm />
      </DashboardLayout>
    ),
  },
  {
    path: "/emp/records",
    element: (
      <DashboardLayout>
        <Records />
      </DashboardLayout>
    ),
  },
  {
    path: "/emp/records/:id",
    element: (
      <DashboardLayout>
        <Record />
      </DashboardLayout>
    ),
  },
  {
    path: "/admin",
    element: (
      <AuthLayout>
        <AdminHome />
      </AuthLayout>
    ),
  },
];

export default routes;
